<template lang="pug">
  .box_size
    //- Header(topClass="col-12" :topStyle="{ 'text-align': center }")
    //- h1.center-text.mt-4(style="font-size: 3rem;") Colmédica 2
    h2.center-text(style="font-size: 1rem;") {{ turn }}
    h4.center-text.mt-4(style="font-size: 1.5rem;") {{ tramite }}
    h5.center-text(style="font-size: 1rem;") {{ branch }}
    h5.center-text(style="font-size: 1rem;") {{ queue }}
    h5.center-text.mt-4(style="font-size: 1rem;") {{ date }}
    h5.center-text.mt-4(style="font-size: 1rem;") {{ date_app }}
</template>

<script>
import Header from "./components/Header";
import moment from "moment";

export default {
  name: "TicketPrint",

  components: {
    Header
  },

  computed: {
    turn() {
      return this.$route.query.turn;
    },

    tramite() {
      return this.$route.query.tramite;
    },

    date() {
      return moment().format("YYYY-MM-DD hh:mm a");
    },

    date_app() {
      return this.$route.query.date_app;
    },

    branch() {
      return this.$route.query.branch;
    },

    queue() {
      return this.$route.query.queue;
    }
  }
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
.box_size {
  width: 60mm;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
</style>
